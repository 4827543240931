import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      name: "Karuna",
      sur_name: "Shrestha",
      //navbar
      home: "Home",
      about_me: "About me",
      my_projects: "My works",
      resume: "Resume",
      //home
      greeting: "Hi!",
      introduction: "I'm Shrestha Karna",
      skill_1: "sales manager",
      skill_2: "video blogger",
      skill_3: "Traveler",
      //home2
      introduction: "Let me introduce myself",
      description:
        "My strength is that I have a desire to improve. As the eldest daughter, I came to Japan because I wanted to support my family.",
      job: "I studied at a Japanese language school for two years in Japan, and then",
      job_position: "studied hotel and business for four years.",
      hobbies:
        " I am currently working in customer service for Softbank, the third largest company in Japan.",
      food_interest: "the third largest company in Japan.",
      find_me: "Find me on",
      connect_with_me: "Feel free to get",
      connected: "connected",
      //footer
      designed_and_developed_by: "Designed and Developed by M & K studio",
      copyright: "Copyright © 2024 Karuna",
    },
  },
  ja: {
    translation: {
      name: "カルナ",
      sur_name: "シュレスタ",
      //navbar
      home: "家",
      about_me: "について",
      my_projects: " 経験",
      resume: "再開する",
      //home
      greeting: "やあ!",
      introduction: "私は シュレスタ・カルナです",
      skill_1: "営業部長",
      skill_2: "ビデオブロガー",
      skill_3: "旅行者",
      //home2
      introduction: "自己 紹介 します",
      description:
        "私の長所は向上心を持っていることです。私は長女で家族を支えたいという気持ちがあり日本へ来ました。",
      job: "日本来て2年間日本語学校勉強してその後4年間。",
      job_position: "ホテルとビジネスの勉強しました",
      hobbies: "現在ソフトバンクという日本でも3番目の会社の接客のお仕事",
      food_interest: "やっています。",
      find_me: "で私を見つけてください",
      connect_with_me: "自由に私とつ",
      connected: "ながりました",
      //footer
      designed_and_developed_by:
        "によって設計および開発されました M & K studio",
      copyright: "著作権 © 2024 カルナ",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ja", // Default language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
