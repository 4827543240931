import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import { Link } from "react-router-dom";
import flagEn from "../Assets/Flag-United-Kingdom.webp";
import flagJa from "../Assets/Flag_of_Japan.svg";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";
import { useTranslation } from "react-i18next";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="" height="40" alt="brand" />
          <span className="d-flex flex-column">
            <span className="my-auto" style={{ fontSize: "14px" }}>
              {t("name")}
            </span>
            <span
              className="my-auto"
              style={{ fontSize: "14px", marginLeft: "20px" }}
            >
              {t("sur_name")}
            </span>
          </span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome
                  style={{ marginBottom: "2px", color: "#FF1493" }}
                />{" "}
                <span style={{ color: "#FF1493" }}>{t("home")}</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser
                  style={{ marginBottom: "2px", color: "#FF1493" }}
                />{" "}
                <span style={{ color: "#FF1493" }}>{t("about_me")}</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/project"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px", color: "#FF1493" }}
                />{" "}
                <span style={{ color: "#FF1493" }}>{t("my_projects")}</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument
                  style={{ marginBottom: "2px", color: "#FF1493" }}
                />{" "}
                <span style={{ color: "#FF1493" }}>{t("resume")}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <nav>
                <ul style={{ listStyle: "none", display: "flex", padding: 0 }}>
                  {language === "en" ? (
                    <li
                      onClick={() => changeLanguage("ja")}
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      className="mt-2 mx-auto"
                    >
                      <img
                        src={flagJa}
                        alt="日本語"
                        style={{ width: "30px", height: "20px" }}
                      />
                    </li>
                  ) : (
                    <li
                      onClick={() => changeLanguage("en")}
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      className="mt-2 mx-auto"
                    >
                      <img
                        src={flagEn}
                        alt="English"
                        style={{ width: "30px", height: "20px" }}
                      />
                    </li>
                  )}
                </ul>
              </nav>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
