import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            皆さんこんにちは、<span className="purple">私はカルナ </span>
            <br />
            私は日本に住んでい <span className="purple">る勤勉</span>
            な女の子です。
            <br />
            私は現在、日本の会社で営業責任者として働いています。
            私は顧客を扱い、 <span className="purple">顧客を満足</span>
            させるのがとても上手です ・{" "}
            <span className="purple">シュレスタです</span>。
            <br />
            <br />
            仕事以外に、私がやりたい活動がいくつかあります!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight />
              おいしいものを食べる
            </li>
            <li className="about-activity">
              <ImPointRight />
              美しい場所への旅行
            </li>
            <li className="about-activity">
              <ImPointRight />
              ビデオブログを作る
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "だいじはしょうじよりおこる"{" "}
          </p>
          <footer className="blockquote-footer">カルナ(Karuna)</footer>
        </blockquote>
      </Card.Body>
    </Card>

    
  );
}

export default AboutCard;
